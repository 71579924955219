import { store as imageStore } from '../../image'
import Command from '../command'
import { deepGet } from '../obj'
import { i18n } from '../utils'

function info(message, defaultVal) {
  console.warn(message)
  return defaultVal
}

function keyRequired(key, path = null) {

  if (path) {
    path = `.${path}`
  }

  // eslint-disable-next-line max-len
  return `requires \`data${path}.${key}\` (from \`contextStore.target.${key}\`) to be present`

}

/**
 * The InsertImageFromContextIntoArticlePlaceholderCommand uses the data in the
 * context store to edit and upload an image and finally insrt it into the
 * article placeholder.
 * @extends shared~Command
 */
export default
class InsertImageFromContextIntoArticlePlaceholderCommand
  extends Command {

  /**
   * Creates a new InsertImageFromContextIntoArticlePlaceholderCommand using
   * the data from the contextStore.
   */
  constructor(imageData, placeholderData, opts) {

    placeholderData.image = imageData

    super(placeholderData)

    this.data = placeholderData
    this.opts = opts
  }

  /**
   * Validates the data in this command.
   * @param {Object} data - The data of the command
   * @private
   */
  validate(data) {

    return ['article', 'image', 'pid']

      .filter(key => !(key in data) || !data[key])
      .map(key => keyRequired(key))

      .concat(
        ['value']
          .filter(key => data.image && (!(key in data.image) || !data.image[key]))
          .map(key => keyRequired(key, 'image'))
      )

      .concat(
        ['file', 'url']
          .filter(key => data.sourceType === key)
          .filter(key => data.image && (!(key in data.image) || !data.image[key]))
          .map(key => keyRequired(key, 'image'))
      )

      .concat(
        ['channel']
          .filter(key => data.article && (!(key in data.article) || !data.article[key]))
          .map(key => keyRequired(key, 'article'))
      )

      .join(', ')

  }

  /**
   * Executes the InsertImageFromContextIntoArticlePlaceholderCommand.
   */
  exec() {

    const channel = this.data.article.channel
    const lang = this.data.article.createdIso

    return this.getEditableImageInstances()
      .then(dataSet => this.editImages(dataSet, channel, lang))
      .then((model) => {
        if (model && !(model instanceof Error)) {
          return this.placeImageInPlaceholder(model, channel)
        }
        return null
      })
      .then(() => {
        if (this.data.article) {
          return this.data.article.save({ noBackendSet: true })
        }
        return null
      })
  }

  /**
   * Actual image editing action.
   * @private
   * @param {Array} dataSet - An array of editable images.
   * @param {String} channel - channel to add image to
   * @param {String} requestedLang - the lang desired for the image
   * @returns {Promise} - A promise that eventually delivers the result of
   * the article action article~Model#placeDataInPlaceholder.
   */
  editImages(dataSet, channel, requestedLang) {
    let promise
    // If there are more than one image the zip logic needs to be used
    if (dataSet.length > 1 || this.isZipFile(this.data.image)) {
      // If the image comes from a zip file. the imageData.image
      // property will be the zip file, so we have to overwrite
      // it here:
      promise = this.getMultipleImagesData(dataSet)
    }
    else if (dataSet[0].image.url
      && dataSet[0].image.imageData
      && dataSet[0].image.imageData.length > 1) {

      const tmpDataSet = []
      dataSet[0].image.imageData.forEach((urlImage) => {
        // Copy the already build structure
        const tmp = JSON.parse(JSON.stringify(dataSet[0]))
        delete tmp.image
        const tmpImage = JSON.parse(JSON.stringify(dataSet[0].image))
        delete tmpImage.imageData
        Object.assign(tmpImage, urlImage)
        tmp.image = tmpImage

        // save
        tmpDataSet.push(tmp)
      })
      // continue with multiple images through url
      promise = this.getMultiUrlImageData(tmpDataSet)
    }
    else {
      // This is ALWAYS a single file if we were not uploading a zip.

      if (deepGet(dataSet, '0.image.imageData.0')) {
        const tmp = JSON.parse(JSON.stringify(dataSet[0]))
        delete tmp.image
        const tmpImage = JSON.parse(JSON.stringify(dataSet[0].image))
        delete tmpImage.imageData
        Object.assign(tmpImage, deepGet(dataSet, '0.image.imageData.0'))
        dataSet[0].image = tmpImage
      }

      promise = this.getSingleImageData(dataSet)
    }

    return promise
      .then((imageData) => {
        if (!(imageData && imageData.length)) {
          throw new Error('No images to be edited')
        }
        return imageData
      })
      .then(imageData => imageStore.editImages(
        imageData,
        channel,
        requestedLang,
        this.opts
      ))
      .catch((ex) => {
        console.log(ex.message)
        // allows up-level promises to know
        throw ex
      })

  }

  getMultiUrlImageData(data) {
    // Get the current image id if there is any so it can be
    // replaced.
    const currImage = this.data.article.getDataInPlaceholder(this.data.pid)

    if (currImage) {
      data[0].image.id = currImage.id
    }

    let promise = Promise.resolve(data)

    promise = this.ensureImagesMeetConstraints(data)

    return promise
      .then((filteredDataSet) => {

        if (filteredDataSet.length < 1) {
          this.trigger('error:constraint-failure', {
            count: 1 - filteredDataSet.length
          })

          if (!filteredDataSet.length) {
            return []
          }
        }

        return filteredDataSet.map((imageData) => {
          return {
            image: imageData.image,
            constraints: imageData.constraints
            || imageData.image.constraints,
            size: imageData.size || info('MissingImageSize', {})
          }
        })
      })
      .catch((err) => {
      // Ensure the original error message is coming back
        throw err
      })

  }

  getSingleImageData(dataSet) {

    const data = dataSet[0]

    // Get the current image id if there is any so it can be
    // replaced.
    const currImage = this.data.article.getDataInPlaceholder(this.data.pid)

    if (currImage) {
      data.image.id = currImage.id
    }

    let promise = Promise.resolve([data])

    promise = this.ensureImagesMeetConstraints([data])

    return promise
      .then((filteredDataSet) => {

        if (!filteredDataSet) {
          return []
        }

        if (filteredDataSet.length < 1) {
          this.trigger('error:constraint-failure', {
            count: 1 - filteredDataSet.length
          })

          return []
        }

        const imageData = filteredDataSet[0]

        if (imageData.constraints) {
        // eslint-disable-next-line max-len
          console.error('NOTE: The image has a `imageData.constraints` dataset. That\'s probably unused. Check if not `imageData.image.constraints` can be used.')
        }

        return [{
          image: imageData.image,
          constraints: imageData.constraints
          || imageData.image.constraints,
          size: imageData.size || info('MissingImageSize', {})
        }]

      })
      .catch((err) => {
      // Ensure the original error message is coming back
        throw err
      })

  }

  getMultipleImagesData(dataSet) {

    return this.ensureImagesMeetConstraints(dataSet)
      .then((filteredDataSet) => {

        if (filteredDataSet.length < dataSet.length) {
          this.trigger('error:constraint-failure', {
            count: dataSet.length - filteredDataSet.length
          })

          if (!filteredDataSet.length) {
            return []
          }
        }

        return filteredDataSet.map(imageData => ({
          ...imageData,
          image: {
          // temporary id of the image as assigned by the zip upload
            id: imageData.img ? imageData.img.id : imageData.image.id,
            constraints: imageData.image.constraints,
            frame: imageData.image.frame,
            type: 'image',
            // .tiff is not supported in browsers. In this case backend prepares a .jpg preview
            mimeType:
            i18n(imageData.img, 'draft.preview.mimeType', imageData.article.createdIso)
            || i18n(imageData.img, 'draft.original.mimeType', imageData.article.createdIso),
            url:
            i18n(imageData.img, 'draft.preview.url', imageData.article.createdIso)
            || i18n(imageData.img, 'draft.original.url', imageData.article.createdIso)
          },
        // This would cause an update on a tiff image executed as batch command
        // multiple: true
        }))
      })

  }

  ensureImagesMeetConstraints(images) {

    return Promise.all(images.map(imageData => this.getImageSize(imageData)))
      .then((loadedImages) => {

        return loadedImages
          .filter(loadedImage => this.ensureImageMeetsConstraints(loadedImage))
          .map(loadedImage => loadedImage.imageData)

      })
      .catch((err) => {

        if (err.message === 'Invalid Image') {
          this.trigger('error:invalid-url', {
            url: err.url
          })
          throw err
        }
        else if (err.message === 'Editing gif') {
          this.trigger('error:editing-gif')
        }
        else if (err.message === 'no svg support') {
          this.trigger('error:svg-error')
        }

      })
  }

  ensureImageMeetsConstraints({
    constraints: { minWidth, minHeight, svgSupport },
    imageData,
    imageData: {
      size: { width, height },
      image: { value, file, sourceType, mimeType }
    }
  }) {

    const minWidthMet = minWidth
      ? minWidth <= width
      : true

    const minHeightMet = minHeight
      ? minHeight <= height
      : true

    imageData.image.fitting = false

    // If the image fits perfectly in the placeholder
    if (minHeight === height && minWidth === width) {
      imageData.image.fitting = true
    }
    else if (file && file.type === 'image/svg+xml') {

      if (!svgSupport) {
        // Fail if placeholder has no svgSupport active inside the spec
        throw new Error('no svg support')
      }

      // svg can be scaled and therefore always fits
      imageData.image.fitting = true
      return true
    }
    else if (file && file.type === 'image/gif') { // via file upload

      // If there is no minHeight defined and it is a gif, skip the cropper too (as design wants)
      if (!minHeight && minWidth === width) {
        imageData.image.fitting = true
      }
      // If animated gif (more than 1 frame) and not fitting exactly, return false
      else if (this.isAnimatedGif(value)) {
        return false
      }

    }
    else if (value.match(/\.(gif)/) // via URL upload
      && (sourceType === 'url' && mimeType === 'gif')) { // via MM upload

      return false
    }
    else if (value.match(/\.(svg)/)) { // via URL or MM upload

      if (!svgSupport) {
        // Fail if placeholder has no svgSupport active inside the spec
        throw new Error('no svg support')
      }

      // svg can be scaled and therefore always fits
      imageData.image.fitting = true
      return true
    }

    if (this.opts.editing && mimeType === 'gif') { // via placeholder (editing)
      throw new Error('Editing gif')
    }

    return minWidthMet && minHeightMet
  }


  placeImageInPlaceholder(models) {

    if (!this.data.multiple && models) {
      models = models[0]

      // check for possible metas to be placed into placeholder
      const imageMetas = deepGet(this.data, 'image.imageMetas')
      if (imageMetas) {
        models.setMetas(imageMetas)
      }
    }

    if (this.data.image.source) {
      models.source = this.data.image.source
    }

    return this.data.article.placeDataInPlaceholder(
      this.data.type, this.data.pid, models
    )
  }

  /**
   * Returns a list of editable images. Editable images are either objects
   * that contain an (image) file or an url to an image.
   * @private
   * @return {Promise} - A promise that will deliver a list of editable images
   * eventually.
   */
  getEditableImageInstances() {

    if (this.isZipFile(this.data.image)) {
      return this.uploadZip(
        this.data.image.file,
        this.data.image.constraints,
        this.data.article.channel,
        this.data.article.createdIso
      )
    }

    return Promise.resolve([this.data])

  }

  /**
   * Check if the image is really an animated gif
   * or contains just one frame
   * @return {Boolean}
   */
  isAnimatedGif(imageData) {
    const base64 = imageData.substr(imageData.indexOf(',') + 1)
    const binaryString = window.atob(base64)
    const len = binaryString.length
    const bytes = new Uint8Array(len)
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i)
    }
    const buffer = bytes.buffer

    // offset bytes for the header section
    const HEADER_LEN = 6
    // offset bytes for logical screen description section
    const LOGICAL_SCREEN_DESC_LEN = 7

    // Start from last 4 bytes of the Logical Screen Descriptor
    const dv = new DataView(buffer, HEADER_LEN + LOGICAL_SCREEN_DESC_LEN - 3)
    let offset = 0
    const globalColorTable = dv.getUint8(0)  // aka packet byte
    let globalColorTableSize = 0

    // check first bit, if 0, then we don't have a Global Color Table
    if (globalColorTable & 0x80) {
      // grab the last 3 bits, to calculate the global color table size -> RGB * 2^(N+1)
      // N is the value in the last 3 bits.
      globalColorTableSize = 3 * (2 ** ((globalColorTable & 0x7) + 1))
    }

    // move on to the Graphics Control Extension
    offset = 3 + globalColorTableSize

    const extensionIntroducer = dv.getUint8(offset)
    const graphicsConrolLabel = dv.getUint8(offset + 1)
    let delayTime = 0

    // Graphics Control Extension section is where GIF animation data is stored
    // First 2 bytes must be 0x21 and 0xF9
    if ((extensionIntroducer & 0x21) && (graphicsConrolLabel & 0xF9)) {
      // skip to the 2 bytes with the delay time
      delayTime = dv.getUint16(offset + 4)
    }

    return delayTime > 0
  }

  /**
   * Validates that a suspected file is actually a zip file.
   * @private
   * @param {Object} file - The file to be checked
   */
  isZipFile(file) {
    return file.file instanceof Blob
      && file.file.type === 'application/zip'
  }

  /**
   * Uploads a zip to the server and returns a promise that will eventually
   * deliver a list of json image objects containing the URLs of the uploaded
   * images.
   * @private
   * @param {Blob} file - The ZIP file Blob
   * @param {Object} constraints - The constraints for this image. May be used
   * serverside to remove images from the list that do not match them.
   * @returns {Promise} - The promise of the upload
   */
  uploadZip(file, constraints, channel, lang) {

    return imageStore.uploadImage({
      file,
      constraints,
      channel },
    { params: { lang }, ...this.opts }
    )
      .then(images => images.map(img => ({
        ...this.data,
        img,
      })))

  }


  getImageSize(imageData) {

    return new Promise((resolve, reject) => {

      const img = new Image()

      img.onload = function imageLoaded() {

        imageData.size = {
          width: this.width,
          height: this.height,
        }

        resolve({
          imageData,
          constraints: imageData.constraints || imageData.image.constraints,
        })

      }
      // Use converted preview for zipped and not supported filetypes.
      // Use original for zipped, but supported filetypes,
      // defined with multiple in template spec
      let objPath = 'draft.preview.url'
      if (imageData.multiple) {
        objPath = 'draft.original.url'
      }

      img.onerror = function imageError() {
        reject({
          message: 'Invalid Image',
          url: imageData.image.url
        })
      }

      // imageData.img exists if zip
      img.src = imageData.img
        ? i18n(imageData.img, objPath, imageData.article.createdIso)
        : imageData.image.value || imageData.image.url


      if (!img.src) {
        throw new Error('Missing image url')
      }

    })

  }


}
